import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import { Container } from "reactstrap";
import { PortfolioCardArray } from "../../DummyData";

import Link from "next/link";
import {
  PortfolioBottom,
  PortfolioBreadcrumb,
  PortfolioButton,
  PortfolioCardLanding,
  PortfolioCardsLanding,
  PortfolioHeading,
  PortfolioImage,
  PortfolioInnerText,
  ShowProjectsButton,
} from "../../StyledComponents/LandingStyled";

export default function PortfolioCards() {
  const [showAll, setShowAll] = useState(false);
  const router = useRouter();

  const visibleItems = showAll
    ? PortfolioCardArray
    : PortfolioCardArray.slice(0, 1);
  const customEase = [0, 0, 0.5, 1];

  return (
    <Container className="p-b-section">
      {visibleItems.map((j) => (
        <motion.div
          initial={{ opacity: 0, y: 25 }}
          transition={{
            duration: 1,
            ease: customEase,
            delay: 0.0,
          }}
          whileInView={{ opacity: 1, y: 0 }}
          key={j.id}
        >
          <PortfolioCardsLanding
            className={j.id === "5" && "turn-development-two"}
            key={j.id}
            bg={j.bg}
            color={j.color}
          >
            <PortfolioCardLanding className="xl:!p-[5rem] md:!p-[2rem] md:!pe-[0]">
              <PortfolioBreadcrumb>{j.breadcrumb}</PortfolioBreadcrumb>
              <PortfolioHeading>{j.heading}</PortfolioHeading>
              <PortfolioInnerText>{j.contant1}</PortfolioInnerText>
              <PortfolioInnerText>{j.contant2}</PortfolioInnerText>
              <Link href={j.p_link}>
                <PortfolioButton className={j.id === "2" && "mysquard-button"}>
                  <Icon icon="carbon:arrow-up-right" className="" />
                  View Casestudy
                </PortfolioButton>
              </Link>
            </PortfolioCardLanding>
            <PortfolioImage
              className={
                j.id === "5"
                  ? "flex justify-center right-mobile  xl:!w-[30%] xl:!ml-[8rem] md:!w-[50%] md:!ml-[0rem] "
                  : j.id === "4"
                    ? "flex justify-center right-mobile xl:!w-[30%] xl:!ml-[8rem] md:!w-[50%] md:!ml-[0rem]"
                    : "!pt-0"
              }
            >
              <picture>
                <source
                  media="(max-width: 820px)"
                  srcSet={j.srcSet}
                  alt={j.heading}
                />
                <Image
                  className={
                    j.id === "5" ? "w-full" : j.id === "4" ? "w-full" : ""
                  }
                  src={j.img}
                  alt={j.heading}
                  height={769}
                  width={592}
                  loading="lazy"
                />
              </picture>
            </PortfolioImage>
          </PortfolioCardsLanding>
        </motion.div>
      ))}
      <PortfolioBottom>
        {showAll ? (
          <ShowProjectsButton
            className="dark-btn text-[#FFF] common-btn-hover"
            onClick={() => router.push("/case-study")}
          >
            More Case Studies!{" "}
            <motion.span
              animate={{ x: [3, -4, 3], y: [-3, 4, -3] }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "linear",
              }}
            >
              {/* <Icon icon={`solar:arrow-down-outline`} className="more-icon" /> */}
              <Image
                src="/svgs/Right-Arrow-White.svg"
                alt="Right-Arrow-White"
                width={16}
                height={16}
                loading="lazy"
                className="w-full rotate-[320deg]"
              />
            </motion.span>
          </ShowProjectsButton>
        ) : (
          <ShowProjectsButton
            className="dark-btn text-[#FFF] common-btn-hover"
            onClick={() => setShowAll(!showAll)}
          >
            {!showAll ? "Show more" : "Hide"} Case Studies!{" "}
            <motion.span
              animate={{ y: [0, 10, 0] }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "mirror",
                stiffness: 100,
              }}
            >
              {/* <Icon icon={`solar:arrow-down-outline`} /> */}
              <Image
                src="/svgs/Right-Arrow-White.svg"
                alt="Right-Arrow-White"
                width={16}
                height={16}
                loading="lazy"
                className="w-full rotate-90"
              />
            </motion.span>
          </ShowProjectsButton>
        )}
      </PortfolioBottom>
    </Container>
  );
}
